import { ActionTypes as types } from './actionTypes';
import action from '../helpers/action';

export function login(data) {
  return action(types.AUTHENTICATION_LOGIN, data);
}

export function loginSuccess() {
  return action(types.AUTHENTICATION_LOGIN_SUCCESS);
}

export function loginFailure(error) {
  return action(types.AUTHENTICATION_LOGIN_FAILURE, error);
}

export function logout() {
  return action(types.AUTHENTICATION_LOGOUT);
}

export function resetPassword(data) {
  return action(types.AUTHENTICATION_RESET_PASSWORD, data);
}

export function resetPasswordSuccess() {
  return action(types.AUTHENTICATION_RESET_PASSWORD_SUCCESS);
}

export function resetPasswordFailure(error) {
  return action(types.AUTHENTICATION_RESET_PASSWORD_FAILURE, error);
}
