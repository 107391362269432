import React from 'react';
import { connect } from 'react-redux';

import { logout } from '../actions/authenticationActions';
import './Login.scss';
import {
  UikContainerVertical,
  UikWidget,
  UikWidgetContent,
  UikButton
} from '../@uik';
import DynamoLogo from '../components/DynamoLogo';

class Dashboard extends React.Component {
  handleLogout = () => {
    this.props.logout();
  };

  render() {
    return (
      <UikContainerVertical className="app-container">
        <UikWidget className="login-box">
          <UikWidgetContent className="content-container">
            <DynamoLogo />
            <h1 className="secondary-text">Dashboard</h1>
            <UikButton className="primary-btn" onClick={this.handleLogout}>
              Log Out
            </UikButton>
          </UikWidgetContent>
        </UikWidget>
      </UikContainerVertical>
    );
  }
}

export default connect(
  null,
  { logout }
)(Dashboard);
