import { ActionTypes as types } from '../actions/actionTypes';
import createReducer from '../helpers/createReducer';

const initialState = {
  isLoggedIn: false,
  loading: false,
  error: null
};

export default createReducer(initialState, {
  [types.AUTHENTICATION_LOGIN]: isLoading,
  [types.AUTHENTICATION_LOGIN_SUCCESS]: loginSuccess,
  [types.AUTHENTICATION_LOGIN_FAILURE]: failure,
  [types.AUTHENTICATION_LOGOUT]: logout,
  [types.AUTHENTICATION_RESET_PASSWORD]: isLoading,
  [types.AUTHENTICATION_RESET_PASSWORD_SUCCESS]: passResetSuccess,
  [types.AUTHENTICATION_RESET_PASSWORD_FAILURE]: failure
});

function isLoading(state, action) {
  return {
    ...state,
    loading: true,
    error: null
  };
}

function loginSuccess(state, action) {
  return {
    ...state,
    isLoggedIn: true,
    loading: false,
    error: null
  };
}

function failure(state, action) {
  return { ...state, loading: false, error: action.data };
}

function logout(state, action) {
  return {
    ...state,
    isLoggedIn: false
  };
}

function passResetSuccess(state, action) {
  return {
    ...state,
    loading: false,
    error: null
  };
}
