import React from 'react';
import PropTypes from 'prop-types';

import './CustomNotification.scss';
import bang from '../assets/images/bang.svg';
import check from '../assets/images/check.svg';
import close from '../assets/images/close-btn.svg';

const CustomNotification = props => {
	return (
		<div className={`notification-custom notification-custom-${props.type}`}>
			<div className="notification-custom-icon">
				<img
					src={props.type === 'danger' ? bang : check}
					alt="Important message"
				/>
			</div>
			<div className="notification-custom-content">
				<p className="notification-message">{props.message}</p>
			</div>
			<div className="notification-custom-close-icon">
				<img src={close} alt="Close button" />
			</div>
		</div>
	);
};

CustomNotification.propTypes = {
	message: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['success', 'danger']).isRequired,
};

export default CustomNotification;
