import React from 'react';
import { Link } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

import Session from '../services/session';
import { login, loginSuccess } from '../actions/authenticationActions';
import {
	UikContainerVertical,
	UikWidget,
	UikFormInputGroup,
	UikInput,
	UikButton,
	UikWidgetContent,
} from '../@uik';
import './Login.scss';
import SocialLogin from '../components/SocialLogin';
import DynamoLogo from '../components/DynamoLogo';
import CustomNotification from '../components/CustomNotification';

class Login extends React.Component {
	state = { email: '', password: '', emailError: '', passwordError: '' };

	notification = React.createRef();
	notificationId = null;

	componentDidMount() {
		const {
			match: { params },
		} = this.props;
		if (params.email) {
			// User came here by clicking an invitation link
			this.setState({ email: params.email });
		}
		const auth = Cookies.get('auth');
		const errorMessage = Cookies.get('error');
		console.log('// auth', auth);
		console.log('// errorMessage', errorMessage);
		if (auth) {
			Cookies.remove('auth', { path: '/', domain: process.env.REACT_APP_DOMAIN });
			const localStorageSession = new Session();
			localStorageSession.set(JSON.parse(auth));
			this.props.loginSuccess();
		}
		if (errorMessage) {
			Cookies.remove('error', { path: '/', domain: process.env.REACT_APP_DOMAIN });
			this.showErrorNotification({ message: errorMessage }, false);
		}
	}

	componentDidUpdate(prevProps) {
		const { loading, error } = this.props;
		if (prevProps.loading !== loading && error) {
			this.showErrorNotification(error);
		}
	}

	showErrorNotification = (error, withState = true) => {
		const message = error.message || 'Something went wrong.';
		this.showNotification('danger', message);
		if (message.includes('User not found') && withState) {
			this.setState({ emailError: 'No user found with that email address' });
		}
	};

	showNotification = (type, message) => {
		if (this.notification) {
			// Nothing will happen if notificationId does not exist
			this.notification.current.removeNotification(this.notificationId);
			// Show notification and save a reference
			this.notificationId = this.notification.current.addNotification({
				container: 'top-right',
				dismiss: { duration: 0 },
				dismissable: { click: true },
				content: <CustomNotification type={type} message={message} />,
			});
		}
	};

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleSubmit = () => {
		const { email, password } = this.state;
		this.setState({ emailError: '' });
		if (!email || !password) {
			this.showNotification('danger', 'Email and password are required.');
		} else {
			console.log('// Logging in');
			this.props.login({ email, password });
		}
	};

	render() {
		const { loading } = this.props;
		const { email, password, emailError, passwordError } = this.state;
		return (
			<UikContainerVertical className="app-container">
				<ReactNotification ref={this.notification} isMobile={false} />
				<UikWidget className="login-box">
					<UikWidgetContent className="content-container">
						<DynamoLogo />
						<h3 className="secondary-text">Login to your Dynamo account</h3>
						<UikFormInputGroup>
							<UikInput
								type="email"
								label="E-mail address"
								name="email"
								onChange={this.handleChange}
								value={email}
								errorMessage={emailError}
							/>
							<UikInput
								type="password"
								label="Password"
								name="password"
								onChange={this.handleChange}
								value={password}
								errorMessage={passwordError}
							/>
							<UikButton
								className="primary-btn"
								onClick={this.handleSubmit}
								isLoading={loading}
								disabled={loading}
							>
								Sign In
							</UikButton>
						</UikFormInputGroup>
						<p
							className="secondary-text center-text"
							style={{ marginTop: 21, marginBottom: 15 }}
						>
							or connect with
						</p>
					</UikWidgetContent>
					<SocialLogin />
				</UikWidget>

				<p className="secondary-text center-text" style={{ marginTop: 24 }}>
					Forgot your password buddy?{' '}
					<span>
						{' '}
						<Link to="/password-reset">Get a new password </Link>
					</span>
				</p>
			</UikContainerVertical>
		);
	}
}

function mapStateToProps(state) {
	return { ...state.authentication };
}
export default connect(
	mapStateToProps,
	{ login, loginSuccess }
)(Login);
