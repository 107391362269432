import React from 'react';

import { UikContainerHorizontal } from '../@uik';
import './SocialLogin.scss';
import slack from '../assets/images/slack.svg';
import facebook from '../assets/images/facebook.svg';
import google from '../assets/images/google.svg';
import SocialButton from './SocialButton';

const SocialLogin = () => {
	return (
		<UikContainerHorizontal className="social-login">
			<SocialButton
				imgSrc={slack}
				alt="Slack Login"
				href={process.env.REACT_APP_SLACK_LOGIN}
				id="slack"
			/>
			<SocialButton
				imgSrc={facebook}
				alt="Facebook Login"
				href={process.env.REACT_APP_FACEBOOK_LOGIN}
				id="facebook"
			/>
			<SocialButton
				imgSrc={google}
				alt="Google Login"
				href={process.env.REACT_APP_GOOGLE_LOGIN}
				id="google"
			/>
		</UikContainerHorizontal>
	);
};

export default SocialLogin;
