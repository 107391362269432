import React from 'react';
import PropTypes from 'prop-types';

import { UikWidgetBottomCta } from '../@uik';
import './SocialButton.scss';

const SocialButton = props => {
	return (
		<UikWidgetBottomCta
			href={props.href}
			className="social-btn"
			id={props.id}
		/>
	);
};

SocialButton.propTypes = {
	href: PropTypes.string,
	imgSrc: PropTypes.string.isRequired,
	alt: PropTypes.string,
	id: PropTypes.string.isRequired,
};
SocialButton.defaultProps = {
	href: '/',
	alt: '',
};

export default SocialButton;
