import enforcedActionTypes from '../helpers/enforcedActionTypes';

export const ActionTypes = enforcedActionTypes(
  // Authentication
  // - Login
  'AUTHENTICATION_LOGIN',
  'AUTHENTICATION_LOGIN_SUCCESS',
  'AUTHENTICATION_LOGIN_FAILURE',
  // - Logout
  'AUTHENTICATION_LOGOUT',
  // - Reset Password
  'AUTHENTICATION_RESET_PASSWORD',
  'AUTHENTICATION_RESET_PASSWORD_SUCCESS',
  'AUTHENTICATION_RESET_PASSWORD_FAILURE'
);
