import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { ActionTypes as types } from '../actions/actionTypes';
import {
  loginFailure,
  loginSuccess,
  resetPasswordFailure,
  resetPasswordSuccess
} from '../actions/authenticationActions';
import { api } from '../services/api';

function* authenticate(action) {
  const { data } = action;
  try {
    yield api.authenticate(data);
    yield put(loginSuccess());
    yield put(push('/'));
  } catch (error) {
    console.log('// Login error', error.response);
    yield put(loginFailure(error.response.data));
  }
}

function* watchLogin() {
  yield takeLatest(types.AUTHENTICATION_LOGIN, authenticate);
}

function* logout() {
  yield api.clearSession();
}

function* watchLogout() {
  yield takeLatest(types.AUTHENTICATION_LOGOUT, logout);
}

function* resetPassword(action) {
  const { data } = action;
  try {
    yield api.resetPassword(data);
    yield put(resetPasswordSuccess());
  } catch (error) {
    console.log('// Reset password error', error.response);
    yield put(resetPasswordFailure(error.response.data));
  }
}

function* watchResetPassword() {
  yield takeLatest(types.AUTHENTICATION_RESET_PASSWORD, resetPassword);
}

export default function*() {
  yield all([watchLogin(), watchLogout(), watchResetPassword()]);
}
