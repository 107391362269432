import React from 'react';
import { Switch } from 'react-router-dom';

import './App.scss';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import PasswordReset from './pages/PasswordReset';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

class App extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <PublicRoute path="/login/:email?" component={Login} />
          <PublicRoute path="/password-reset" component={PasswordReset} />
          <PrivateRoute exact path="/" component={Dashboard} />
        </Switch>
      </>
    );
  }
}

export default App;
