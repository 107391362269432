import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authentication from './authentication';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication']
};

export default history =>
  persistReducer(
    persistConfig,
    combineReducers({
      router: connectRouter(history),
      authentication
    })
  );
