import { SESSION_KEY } from '../utils';

class Session {
  get(key = null) {
    const raw = localStorage.getItem(SESSION_KEY);
    const data = raw ? JSON.parse(raw) : {};
    if (key) {
      return data[key];
    }
    return data;
  }

  set(info, value = null) {
    if (value !== null) {
      const data = this.get();
      data[info] = value;
      return this.set(data);
    }
    return localStorage.setItem(SESSION_KEY, JSON.stringify(info));
  }

  clear(key = null) {
    if (key) {
      const data = this.get();
      delete data[key];
      return this.set(data);
    }
    return localStorage.removeItem(SESSION_KEY);
  }
}

export default Session;
