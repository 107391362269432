import axios from 'axios';

import Session from './session';

class Api {
  constructor({ session, url }) {
    this.session = session;
    this.url = url;
  }

  async authenticate({ email, password }) {
    const url = this.urlForPath('/users/authenticate');
    const result = await axios.post(url, {
      email,
      password
    });
    this.session.set(result.data);
    return result.data;
  }

  async resetPassword({ email, password = null, resetPassToken = null }) {
    let url = '';
    if (password && resetPassToken) {
      url = this.urlForPath('/users/password/reset');
      // Make POST request to reset user's password
      const result = await axios.post(url, {
        email,
        password,
        resetPassToken
      });
      return result.data;
    }
    // Start reset password process
    url = this.urlForPath(`/users/password/reset?to=${email}`);
    const result = await axios.get(url);
    return result.data;
  }

  clearSession() {
    this.session.clear();
  }

  urlForPath(path) {
    return `${this.url}${path}`;
  }
}

export const api = new Api({
  session: new Session(),
  url: process.env.REACT_APP_API_URL
});

export default Api;
