import React from 'react';
import { Link } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import { connect } from 'react-redux';

import { resetPassword } from '../actions/authenticationActions';
import {
  UikContainerVertical,
  UikWidget,
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidgetContent
} from '../@uik';
import './Login.scss';
import SocialLogin from '../components/SocialLogin';
import DynamoLogo from '../components/DynamoLogo';
import CustomNotification from '../components/CustomNotification';

class PasswordReset extends React.Component {
  state = { email: '', emailError: '', reset: false };

  notification = React.createRef();
  notificationId = null;

  componentDidUpdate(prevProps) {
    const { loading, error } = this.props;
    if (prevProps.loading && !loading) {
      if (error) {
        this.showNotification('danger', error.message);
      } else {
        this.showNotification('success', 'Password reset sent');
        this.setState({ reset: true });
      }
    }
  }

  showNotification = (type, message) => {
    if (this.notification) {
      // Nothing will happen if notificationId does not exist
      this.notification.current.removeNotification(this.notificationId);
      // Show notification and save a reference
      this.notificationId = this.notification.current.addNotification({
        container: 'top-right',
        dismiss: { duration: 0 },
        dismissable: { click: true },
        content: <CustomNotification type={type} message={message} />
      });
    }
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = () => {
    const { email } = this.state;
    if (!email) {
      this.showNotification('danger', 'Email is required.');
    } else {
      this.props.resetPassword({ email });
    }
  };

  render() {
    const { loading } = this.props;
    const { email, emailError, reset } = this.state;
    return (
      <UikContainerVertical className="app-container">
        <ReactNotification ref={this.notification} isMobile={false} />
        <UikWidget className="login-box">
          <UikWidgetContent className="content-container">
            <DynamoLogo />
            <h3 className="secondary-text">Password reset</h3>
            <p
              className="secondary-text subtext"
              style={{ marginTop: 0, marginBottom: 39 }}
            >
              {reset
                ? 'Please check your email and follow the password reset instructions to reset your password.'
                : 'Enter the email address used to create your Dynamo account and we’ll email you password reset instructions.'}
            </p>
            {!reset && (
              <>
                <UikFormInputGroup>
                  <UikInput
                    type="email"
                    label="Enter your email address"
                    name="email"
                    onChange={this.handleChange}
                    value={email}
                    errorMessage={emailError}
                  />
                  <UikButton
                    className="primary-btn"
                    onClick={this.handleSubmit}
                    isLoading={loading}
                    disabled={loading}
                  >
                    Reset password
                  </UikButton>
                </UikFormInputGroup>
                <p
                  className="secondary-text center-text"
                  style={{ marginTop: 21, marginBottom: 39 }}
                >
                  or connect with
                </p>
              </>
            )}
          </UikWidgetContent>
          <SocialLogin />
        </UikWidget>
        <p className="secondary-text center-text" style={{ marginTop: 24 }}>
          <span>
            <Link to="/login">Login</Link>
          </span>
        </p>
      </UikContainerVertical>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.authentication };
}
export default connect(
  mapStateToProps,
  { resetPassword }
)(PasswordReset);
