import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';

import devMiddlewares from './devMiddlewares';
import rootSaga from '../sagas';
import createRootReducer from '../reducers';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const enhancer =
  process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;

const store = createStore(
  createRootReducer(history),
  enhancer(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
      ...devMiddlewares
    )
  )
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;
